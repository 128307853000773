import React, { useContext } from "react"
import { AppContext } from "../context/context"
import BasicContentBlock from "../content-blocks/BasicContentBlock"
import * as styles from "./pest-library.module.scss"
const PestList = ({ pests, enabled }) => {
  const { pestLibraryActive, pestLibrarySlug } = useContext(AppContext)
  if (enabled === "kontent_item_pest") {
    let mapRunsAlt = 0
    return (
      <div className={`container ${styles.pestGrid}`}>
        {pests.value.map(({ elements, id }) => {
          mapRunsAlt++
          const { image, name, short_description, slug } = elements
          const pestSlug = pestLibraryActive
            ? `/${pestLibrarySlug}/${slug.value}`
            : `/${slug.value}`
          return (
            <BasicContentBlock
              key={id}
              content={short_description}
              image={image}
              subHeading={name}
              slug={pestSlug}
              mapRuns={mapRunsAlt}
            />
          )
        })}
      </div>
    )
  } else {
    return null
  }
}

export default PestList
